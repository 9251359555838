import service from '@/services/country_service'
import { SET_COUNTRIES } from '../constants'

const state = () => ({
  entity: {}
})
const getters = {}
const actions = {
  async list({ commit }) {
    const { data: countries } = await service.list()
    commit(SET_COUNTRIES, countries)
    return countries
  }
}
const mutations = {
  [SET_COUNTRIES](state, payload) {
    state.entity = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
