import service from "@/services/promotion_service";

const state = () => ({
  packages: [],
  chooseId: null,
});
const getters = {};
const actions = {
  async getPackages({ commit }) {
    const { data } = await service.list();
    commit("setPackagesData", data);
    return data;
  },
  async choose({ state }, payload) {
    console.log(state.chooseId);
    await service.choose(payload.id);
  },
};
const mutations = {
  setPackagesData(state, payload) {
    state.packages = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
