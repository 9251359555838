import service from "@/services/user_service";
import { axiosInstance } from "@/services/base";

const state = () => ({
  email: "",
  fullName: "",
  active: true,
  profile: {},
});
const getters = {};
const actions = {
  async login({ commit }, formData) {
    try {
      const {
        data: { access_token, active, ...profile },
      } = await service.login(formData);

      commit("setJwtToken", access_token, { root: true });
      commit("setActiveState", active);
      commit("setProfile", profile);
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${access_token}`;
      commit("setEmail", formData.username);
      localStorage.setItem("accessToken", access_token);
      return true;
    } catch (e) {
      return false;
    }
  },
  async getProfile({ commit }) {
    const {
      data: { username, active, statusCode, ...profile },
    } = await service.getProfile();
    if (statusCode == 401) {
      commit("setJwtToken", null, { root: true });
      localStorage.removeItem("accessToken");
    } else {
      commit("setActiveState", active);
      commit("setProfile", profile);
      commit("setEmail", username);
    }
    return { statusCode, profile };
  },
  async register(_, formData) {
    const result = await service.register(formData);
    return result;
  },
  async forgotPassword(_, formData) {
    service.forgotPassword(formData);
  },
  async resetPassword(_, payload) {
    service.resetPassword(payload.token, payload.password);
  },
  async logout({ commit }) {
    commit("setJwtToken", null, { root: true });
    localStorage.removeItem("accessToken");
  },
};
const mutations = {
  setEmail(state, payload) {
    state.email = payload;
  },
  setActiveState(state, payload) {
    state.active = payload;
  },
  setProfile(state, payload) {
    state.profile = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
