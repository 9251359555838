import Vue from "vue";
import Router from "vue-router";
import routes from "vue-auto-routing";
// import store from "./store";
import { createRouterLayout } from "vue-router-layout";
// import { LoadingProgrammatic as loadingComponent } from "buefy";
// import { ToastProgrammatic as Toast } from "buefy";

Vue.use(Router);

const RouterLayout = createRouterLayout((layout) => {
  const layoutName = layout?.name ?? 'home'
  return import("@/layouts/" + layoutName + ".vue");
});

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: RouterLayout,
      children: routes,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  return next();
  // if (
  //   to.name == "register" ||
  //   to.name == "register-complete" ||
  //   to.name == "forgot-password"
  // )
  //   next();
  // else if (to.name === "live") {
  //   const result = await store.dispatch("user/getProfile");
  //   if (store.state.token === null || result.statusCode === 401) {
  //     next({ name: "login" });
  //   } else {
  //     next();
  //   }
  // } else if (to.name == "register" && store.state.token !== null)
  //   next({ name: "index" });
  // // else if (to.name == "promotion") {
  // //   const loading = loadingComponent.open({
  // //     container: null,
  // //   });
  // //   const result = await store.dispatch("promotion/getPackages");
  // //   loading.close();
  // //   if (
  // //     store.state.user.active === true ||
  // //     result.statusCode == 401 ||
  // //     result.statusCode == 403
  // //   ) {
  // //     Toast.open({
  // //       message: "You not have access to this page.",
  // //       type: "is-danger",
  // //     });
  // //   } else if (store.state.currentEventStep !== 1) {
  // //     Toast.open({
  // //       message: "This section can visit soon.",
  // //       type: "is-danger",
  // //     });
  // //   } else {
  // //     next();
  // //   }
  // // } else if (
  // //   to.name == "promotion" ||
  // //   to.name == "to_survey" ||
  // //   to.name == "to_virtual"
  // // ) {
  // //   const loading = loadingComponent.open({
  // //     container: null,
  // //   });
  // //   await store.dispatch("getLive");
  // //   loading.close();
  // //   next();
  // // }
  // else next();
});

export default router;
