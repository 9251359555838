import { axiosInstance } from "./base";

export default {
  login(formData) {
    return axiosInstance.post("auth/login", formData);
  },
  getProfile() {
    return axiosInstance.get("users/profile").catch((error) => {
      
      return error.response;
    });
  },
  list() {
    return axiosInstance.get("users");
  },
  register(formData) {
    return axiosInstance.post("users/register", formData).catch((error) => {
      
      return error.response;
    });
  },
  forgotPassword(formData) {
    return axiosInstance
      .post("users/forgot-password", formData)
      .catch((error) => {
        
        return error.response;
      });
  },
  resetPassword(token, password) {
    return axiosInstance.post("/users/reset-password", {
      token,
      password: password,
    });
  },
};
