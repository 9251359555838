import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./services/base";
import Vuelidate from "vuelidate";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueEllipseProgress from "vue-ellipse-progress";
import { axiosInstance } from "@/services/base";
import vuetify from './plugins/vuetify'
import Buefy from "buefy";
// import './assets/tailwind.css'

Vue.component(VueCountdown.name, VueCountdown);
Vue.use(Vuelidate);
Vue.use(Buefy, {
  defaultLinkTags: "router-link",
});
Vue.use(VueEllipseProgress);
Vue.config.productionTip = false;

const token = localStorage.getItem("accessToken");
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

if (token != null) {
  store.commit("setJwtToken", token);
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
