import { axiosInstance } from "./base";

export default {
  list() {
    return axiosInstance.get("sale-packages/").catch((error) => {
      return error.response;
    });
  },
  option1() {
    return axiosInstance.get("sale-packages/option1");
  },
  option2() {
    return axiosInstance.get("sale-packages/option2");
  },
  choose(id) {
    return axiosInstance.post("orders", { salePackageId: id });
  },
};
