import Vue from "vue";
import Vuex from "vuex";
import { axiosInstance } from "@/services/base";

import user from "./modules/user";
import country from "./modules/country";
import promotion from "./modules/promotion";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    liveStart: false,
    liveCurrentTime: 0,
    liveEnd: false,
    currentEventStep: 0,
  },
  mutations: {
    setJwtToken(state, token) {
      state.token = token;
    },
    setLiveStart(state, payload) {
      state.liveStart = payload;
    },
    setLiveEnd(state, payload) {
      state.liveEnd = payload;
    },
    setLiveCurrentTime(state, token) {
      state.liveCurrentTime = token;
    },
    setCurrentEventStep(state, step) {
      state.currentEventStep = parseInt(step);
    },
  },
  actions: {
    async getLive({ commit }) {
      const {
        data: { started, currentTime, ended, currentEventStep },
      } = await axiosInstance.get("/live");
      commit("setLiveStart", started);
      commit("setLiveEnd", ended);
      commit("setLiveCurrentTime", currentTime);
      commit("setCurrentEventStep", currentEventStep);
      return true;
    },
    async getCountdown() {
      const {
        data: { target, now },
      } = await axiosInstance.get("/countdown");
      return { target, now };
    },
  },
  modules: {
    user,
    country,
    promotion,
  },
});
