<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
import { axiosInstance } from "@/services/base";
export default {
  created() {
    axiosInstance.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      (error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("accessToken");
          this.$store.commit("setJwtToken", null);
          // this.$router.push("/login");
        }

        return Promise.reject(error);
      }
    );
  },
  mounted() {
    this.$store.dispatch("user/getProfile");
  },
};
</script>
